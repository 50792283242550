@use '@angular/material' as mat;

@mixin slide-toggle-theme($theme) {
    .mat-mdc-slide-toggle {
        &.mat-primary {
            @include _slide-toggle-color($theme, primary);
        }
        &.mat-accent {
            @include _slide-toggle-color($theme, accent);
        }
        &.mat-warn {
            @include _slide-toggle-color($theme, warn);
        }
    }
}

@mixin _slide-toggle-color($theme, $palette) {
    --mdc-switch-selected-icon-color: #{mat.get-theme-color($theme, $palette, default-contrast)};
}
