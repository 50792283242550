mat-table {
  background-color: #303030 !important;
  border: 1px solid #404040 !important;
}

mat-header-cell.mat-mdc-header-cell {
  background-color: #2a2a2a !important;
  border-left: 1px solid #404040 !important;
  color: #aaaaaa !important;
}

mat-row {
  transition: all 0.1s linear !important;

  mat-cell {
    height: 72px !important;
    display: flex !important;
    justify-content: center !important;
    border-left: 1px solid #404040 !important;
  }

  &:hover {
    background-color: #383838 !important;
  }
}

th {
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.25rem !important;
}
