mat-sidenav-container {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
}

mat-sidenav.mat-drawer {
  width: 260px;
  background-color: #303030;
}

.mat-list-item-content {
  font-size: 16px;
}

[mat-list-item] {
  height: 56px !important;
}

[mat-list-item].active {
  transition: all 0.3s linear;
  background-color: rgba(255, 255, 255, 0.1);
}
